/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Subtitle, Button } from '@swp/components'
import SiteHeader from '../../components/de/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Služby"}>
        <SiteHeader />

        <Column className="pb--50 pt--60" name={"einleitung"}>
          
          <ColumnWrap className="column__flex --center el--1 pb--0 pl--0 pr--0 pt--0 flex--center" style={{"maxWidth":1280}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 pb--0 pt--0" anim={"5"} animS={"5"} style={{"maxWidth":900}}>
              
              <Title className="title-box" content={"Dienstleistungen"}>
              </Title>

              <Text className="text-box fs--18" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"dienstleistungen"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --center el--3 flex--top" style={{"maxWidth":""}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"Intrastate transport"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":330}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

              <Subtitle className="subtitle-box" content={"17€"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"Interstate transport"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":330}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

              <Subtitle className="subtitle-box" content={"17€"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"Cargo transport"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":330}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

              <Subtitle className="subtitle-box" content={"17€"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="css-1v78y5t pb--60 pt--60" name={"kontakt"} parallax={false} css={css`
        
    background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/159/fullscreen-2_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/159/fullscreen-2_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/159/fullscreen-2_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/159/fullscreen-2_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/159/fullscreen-2_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/159/fullscreen-2_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/159/fullscreen-2_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/159/fullscreen-2_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s2" anim={"7"} animS={"2"}>
              
              <Title className="title-box fs--48 mt--16" style={{"maxWidth":966}} content={"<span style=\"color: white;\">Where are we going?</span><br>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2" content={"Bestellung"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"partei"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 el--3" anim={"2"} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Land Transport</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-dominant);\">Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Über uns</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-dominant);\">Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Kontakt</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: var(--color-dominant);\">+49 797 811 2X05<br>Oeder Weg 22</span>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: var(--color-dominant);\">Firmen-Ident.-Nr.: 12345678<br>Erstellt mit <a href=\"https://saywebpage.com\">saywebpage.com</a></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}